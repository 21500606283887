<template>

  <div><h2>来宾登记表Visitor Registration Form</h2></div>

  <div>
    <pre>
      <div class="title"><h3>注意事项Notice</h3></div>
      <p class="content">1、来宾进入展示现场均须签订来宾登记表。 Please sign the Visitor Registration Form before you enter the venues</p>
      <p class="content">2、整个展示现场，只允许被邀请的来宾单独进入参观，所有陪同到来的亲属朋友请在来宾休息室等候。 Only the invited visitors are allowed into the venue; all the companions please wait in the respondent waiting room.</p>
      <p class="content">3、来宾在指定区域活动，均须佩戴来宾证；只有在工作人员的陪同下，来宾才能进入其他区域。 Please wear the Visitor Card throughout the whole activity, during when, every visitor must be accompanied by certain staff.</p>
      <p class="content">4、来宾进入展示现场后，不得大声喧哗；必须注意并遵守明示指示牌上的规定（如禁止吸烟等）。 Please do not speak aloud after entering the venue; your cooperation on the notice board (e.g. No smoking) will be greatly appreciated.</p>
      <p class="content">5、主办方希望来宾能尽量配合完成本次活动，并将于活动结束后发放礼品。但来宾在任何时间都具有无理由退出本活动的权利。The organizer expects respondent could make effort to complete the activity, whereas gift is available as the reward. However, every respondent is entitled to quit the activity anytime without any reason.</p>
      <p class="content">6、主办方承诺本次活动得到国家相关部门的批准，符合相关法律之规定和许可。主办方承诺对展示区域内的安全负责，对此承担相关法律规定内的责任。 The organizer promises this activity is ratified by the relevant government authorities and is in line with the regulations and permission of relevant laws. The organizer takes the responsibility of security in the venue and will assume the responsibility within relevant laws.</p>
    </pre>
    <pre>
      <div class="title"><h3>保密须知Confidentiality Agreement</h3></div>
      <p class="content">我们这次活动牵涉到一些“保密信息”。如果您是一位符合我们要求的来宾，并且同意来参加我们的活动，我们需要您配合签署这份“保密须知“。只有得到您的承诺和帮助，我们才能持续地在未来进行类似的活动。此“保密须知”一经签署，则表明您： This activity involves some “confidential information”. If you are a qualified visitor and agree to take part in our activity, we require you to sign this “Confidentiality Agreement”. Only with your promise and assistant, are we enable to continue projects like this in the future. Once the “Confidentiality Agreement” is signed, it means you:</p>
      <p class="content">1、已认真阅读本“保密须知”并同意里面的所有内容。 Have serious-minded read this “Confidentiality Agreement” and agreed with all its contents.</p>
      <p class="content">2、没有携带任何摄影/摄像器材、录音设备，以及其他任何可能具有上述功能的设备（如手机、PDA、MP3播放器等）进入展示区域。主办方设有专门的物品寄存区保管此类物品，并具有使用相关仪器探测和检查此类物品的权利。 Do not carry any image or video capturing device, any voice recording device, or any other equipment that may have the above-mentioned functions (e.g. cell phone, PDA, MP3 Player, etc.) into the venue. The organizer sets up a security desk for your temporary storage and is entitled to scan such objects with relevant instruments.</p>
      <p class="content">3、没有携带任何含有“保密信息”的资料离开现场。 Will not leave the venue with any material involving any “confidential information”.</p>
      <p class="content">4、在“保密信息”被合法公布于众之前不“向外界透露”这些信息。 Will not “reveal to the public” these “confidential information” unless they have been lawfully published</p>
      <p class="content">5、“保密信息”的范围包括您在活动中所接触到的任何： “Confidential information” covers all that you will contact in this activity:</p>
      <span>&nbsp;&nbsp;a) 所展示的产品、图片、影像资料本身，包括但不限于其形状、功能、性能以及其他特性 Displayed products, photos and videos, including but with no limitation to shape, functions, performance and other features</span>
      <span>&nbsp;&nbsp;b) 在研究中涉及到的任何设计理念、新产品概念、商业模式以及市场策略（如价格水平、市场定位等）等 Any design concept, new product concept, commercial mode and market strategy (for example: pricing, market positioning, etc) involved in this research</span>
      <span>&nbsp;&nbsp;c)您个人以及其他来宾对a)和b)的评价反馈信息 The evaluation of you and other visitors to a) and b)</span>
      <span>&nbsp;&nbsp;d)主办方或委托方（厂商）的任何信息 Any information of the organizer and its client (manufacturer)</span>
      <span>&nbsp;&nbsp;e)现场布置、调研方法以及任何从活动中获得的其他资料 Venue setup, research methodology, and any other material obtained from this activity</span>
      <p></p>
      <p class="content">6、“向外界透露”的范围包括：将“保密信息”（不管是口头还是书面）泄露给任何第三方（组织或个人），以及在任何媒体、网站或其他公众场合讨论、公布或张贴这些“保密信息”。 “Revealing to the public” covers:  revealing the “confidential information” (verbal or written) to a third party (organization or individual), as well as discussing, publishing and posting these “confidential information” in any media, Internet websites or other public occasions.</p>
      <p class="content">7、如果违反本保密须知，主办方及活动委托方具有追究相关责任和索求赔偿的权利。If this confidential agreement is bleached, the organizer and its client are entitled to charge the relevant legal duty and claim compensation.</p>
    </pre>
    <el-form :model="formData" label-width="auto" label-position="left" :disabled="subDisabled">
      <el-form-item label="日期 Date">
        <el-date-picker v-model="formData.date" type="datetime" readonly="true" />
      </el-form-item>
      <el-form-item label="姓名 Name">
        <el-input v-model="formData.name"/>
      </el-form-item>
      <el-form-item label="证件名称 ID">
        <el-input v-model="formData.idType"/>
      </el-form-item>
      <el-form-item label="证件号码 ID No">
        <el-input v-model="formData.idNo"/>
      </el-form-item>
      <el-form-item label="联系方式 Contact Info">
        <el-input v-model="formData.contact"/>
      </el-form-item>
      <el-form-item label="单位 Company">
        <el-input v-model="formData.company"/>
      </el-form-item>
      <el-form-item label="地址 Address">
        <el-input v-model="formData.address"/>
      </el-form-item>

      <el-form-item label="签名区">
        <div style="width: 80%;">
          <VPerfectSignature
              :stroke-options="strokeOptions"
              ref="signaturePad"
              height="100px"
              width="80%"
          />
        </div>
      </el-form-item>
      <el-form-item style="display: inline-block">
        <el-button :disabled="subDisabled" @click="clearSign">重置签名</el-button>
        <el-button type="primary" :disabled="subDisabled" @click="sub" >提交</el-button>
      </el-form-item>
    </el-form>
  </div>


</template>

<script>
import VPerfectSignature from 'v-perfect-signature'
import axios from "axios"
import {ElNotification} from "element-plus"

export const hasOwnProperty = (obj,key)=>{
  return typeof obj == "object" && obj != null && Object.prototype.hasOwnProperty.call(obj,key)
}
export const myAxios = (axiosConfig) => {
  const service = axios.create({
    // baseURL:"http://contract.my",
    baseURL:"",
    timeout:10000,
  })
  service.interceptors.request.use(config => {
    config.headers.Authorization = "walter fong"
    return config
  },error => {console.log("request interceptors err",error)})

  service.interceptors.response.use(response => {
    if (!hasOwnProperty(response,"status") || response.status !== 200) return response

    return response.data
  },error => {
    console.log("response interceptors error",error)
  })

  return service(axiosConfig)
}

export default {
  name: "ContractView",
  components:{
    VPerfectSignature
  },
  data(){
    return {
      formData:{
        "name":"",
        "contact":"",
        "idType":"",
        "idNo":"",
        "company":"",
        "address":"",
        "signature":"",
        "date":new Date()
      },
      subDisabled:false,
      strokeOptions:{
        size: 4,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5
      }
    }
  },
  methods:{
    sub(){

      if (this.$refs.signaturePad.isEmpty()) return;
      //停用按钮
      this.subDisabled = true
      this.formData.signature = this.$refs.signaturePad.toDataURL();

      let headers = {
        "Content-Type":"application/x-www-form-urlencoded"
      }
      let data = new FormData()
      data.append("date",this.formData.date.getFullYear()+"-"+(this.formData.date.getMonth() < 10 ? "0"+this.formData.date.getMonth() : this.formData.date.getMonth())+"-"+this.formData.date.getDate()+" "+this.formData.date.getHours()+":"+this.formData.date.getMinutes()+":"+this.formData.date.getMinutes() )
      data.append("name",this.formData.name)
      data.append("contact",this.formData.contact)
      data.append("idType",this.formData.idType)
      data.append("idNo",this.formData.idNo)
      data.append("company",this.formData.company)
      data.append("address",this.formData.address)
      data.append("signature",this.formData.signature)
      let axiosOptions = {
        method:'post',
        url:'/api/contract',
        data:data,
        headers:headers
      }
      myAxios(axiosOptions).then((response)=>{
        if (response.code === 1){
          ElNotification({
            title:"登记信息",
            message:response.msg,
            duration: 2000,
            type:'Info'
          })
        }else {
          ElNotification({
            title:"登记信息",
            message:response.msg,
            duration: 2000,
            type:'Info'
          })
        }
      })
    },
    clearSign(){
      this.$refs.signaturePad.clear()
    }
  }
}
</script>

<style scoped>
  .title{
    text-align: center;
  }
  .content{
    font-size: medium;
    display: inline-block;
    text-align: left;
    width: 90%;
  }

  p{
    margin: 5px 5px;
  }
  pre{
    display: block;
    padding: 9.5px;
    margin: 10px 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: left;
    white-space: pre-wrap!important;
  }
  .el-form-item__content{
    justify-content: center;
  }
  canvas{
    border: 1px solid #ccc;
    border-radius: 4px;
  }
</style>